import React from "react";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import Select from "../../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";
import {filtersTypes} from "../../constants";

const FilterTypeSelect = ({value, onChange}) => {
    return (
        <Select
            value={value}
            onChange={e => onChange(e.target.value)}
            label={<IntlMessages id="filter.by" />}
            name="filterType"
            fullWidth
        >
            <MenuItem value={filtersTypes.activityDate} >
                <IntlMessages id="activity.date" />
            </MenuItem>
            <MenuItem value={filtersTypes.downloadDate} >
                <IntlMessages id="download.date" />
            </MenuItem>
        </Select>
    );
};

export default FilterTypeSelect;