import React from "react";
import {Box} from "@material-ui/core";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {useDispatch} from "react-redux";
import {
  downloadTachographFile,
  sendTachographFiles
} from "../../../../../../services/FleetManagement/Tachograph/TachographsFilesService";
import {downloadFile} from "../../../../../../services/FileService";
import {fetchError, fetchSuccess} from "../../../../../../redux/actions";

const ButtonsArea = ({
  selectedFiles,
  setOpenZipDialog,
  setOpenIndividualDialog
}) => {
  const dispatch = useDispatch();
  const onClickDownload = async e => {
    if (selectedFiles.length > 100) {
      dispatch(fetchError(`No es posible descargar más de 100 ficheros (${selectedFiles.length} seleccionados) a la vez. Considere la opción de enviar al correo.`))
    } else {
      const file = await downloadTachographFile(selectedFiles);
      downloadFile(file.filename, file.resourceLink);
    }
  };
  const onClickSend = async () => {
    await sendTachographFiles(selectedFiles);
    dispatch(fetchSuccess("En unos minutos recibirá un correo con las instrucciones para descargar los ficheros."));
  }
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      style={{gap: "2em"}}
      my="1em"
    >
      <Box
        display="flex"
        style={{
          gap: "1rem"
        }}
      >
        <ContainedButton
            onClick={() => setOpenZipDialog(true)}
            fullWidth
            color="primary"
            text={
              <IntlMessages
                  id={"fleet.management.tachograph.files.table.import.zip"}
              />
            }
        />
        <ContainedButton
            onClick={() => setOpenIndividualDialog(true)}
            fullWidth
            color="primary"
            text={
              <IntlMessages
                  id={"fleet.management.tachograph.files.table.import.individual"}
              />
            }
        />
      </Box>
      {selectedFiles.length > 0 && (
        <Box
            display="flex"
            style={{
              gap: "1rem"
            }}
        >
          <ContainedButton
              onClick={onClickDownload}
              fullWidth
              color="primary"
              text={<IntlMessages id={"form.button.download"} />}
          />
          <ContainedButton
              onClick={onClickSend}
              fullWidth
              color="primary"
              text={<IntlMessages id="send.to.email"/>}
          />
        </Box>
      )}
    </Box>
  );
};

export default ButtonsArea;
