import React from "react";
import {Box, Grid} from "@material-ui/core";
import FiltersContainer from "../../../../../Common/Forms/FiltersContainer";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import useForm from "../../../../../../hooks/Common/useForm";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import OutlinedDatePicker from "../../../../../Common/Forms/OutlinedDatePicker";
import DriversSelect from "./DriversSelect";
import VehiclesSelect from "./VehiclesSelect";
import {useDispatch, useSelector} from "react-redux";
import {applyTachographFilesFilters} from "../../../../../../redux/actions/FleetManagement/TachographFiles";
import FilterTypeSelect from "./FilterTypeSelect";

const initialData = {
  drivers: [],
  vehicles: [],
  filterType: 1,
  fromDate: "",
  toDate: ""
};

const Filters = () => {
  const dispatch = useDispatch();
  const {filters} = useSelector(
    ({fleetManagement}) => fleetManagement.tachographFiles
  );
  const {formData, setFormData, setValues} = useForm(initialData);
  const onClickSearch = e => {
    e.preventDefault();
    dispatch(applyTachographFilesFilters(formData));
  };
  React.useEffect(() => {
    setValues(filters);
  }, []);
  return (
    <Box my="1em">
      <FiltersContainer active>
        <form>
          <GridContainer>
            <Grid item xs={12} md={6}>
              <DriversSelect formData={formData} setFormData={setFormData} />
            </Grid>
            <Grid item xs={12} md={6}>
              <VehiclesSelect formData={formData} setFormData={setFormData} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <FilterTypeSelect
                  value={formData.filterType}
                  onChange={value => setFormData("filterType", value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OutlinedDatePicker
                fullWidth
                label={<IntlMessages id={"form.field.from.date"} />}
                value={formData.fromDate}
                onChange={value => setFormData("fromDate", value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OutlinedDatePicker
                fullWidth
                label={<IntlMessages id={"form.field.to.date"} />}
                value={formData.toDate}
                onChange={value => setFormData("toDate", value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ContainedButton
                fullWidth
                color="primary"
                text={<IntlMessages id={"form.button.search"} />}
                onClick={onClickSearch}
              />
            </Grid>
          </GridContainer>
        </form>
      </FiltersContainer>
    </Box>
  );
};

export default Filters;
